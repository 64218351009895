const digitsInDecemialPart = 2;

export const moneyDisplayView = (value: number | string) => {
  return Number(value).toLocaleString(
    'en-US',
    {
      minimumFractionDigits: digitsInDecemialPart,
      maximumFractionDigits: digitsInDecemialPart,
    },
  );
};
