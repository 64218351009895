import { Input } from 'components/UIComponents/index';
import { shortFieldStyles } from './styledComponents';

interface DependentSelectsBlockProps {
  grossRateValue: string | '';
  netRateValue: string | '';
  grossRateError?: string;
  netRateError?: string;
  setFieldValue: (name: string, value: any) => void;
  isNetRateDisabled?: boolean;
  isPaid?: boolean;
}

const CALCULATE_VALUE = 0.85;
const MULTIPLIER = 100;

const RateFields = ({
  grossRateValue,
  netRateValue,
  grossRateError,
  netRateError,
  setFieldValue,
  isNetRateDisabled = false,
  isPaid = false,
}: DependentSelectsBlockProps) => {
  const onChangeNetValue = (
    grossValue: string,
    setFieldValue: (field: string, value: any) => void,
    grossErrors?: string
  ) => {
    if (grossValue && !grossErrors) {
      const netValue =
        Math.round(Number(grossValue) * CALCULATE_VALUE * MULTIPLIER) /
        MULTIPLIER;
      setFieldValue('netRate', netValue);
    }
  };

  const onChangeGrossValue = (
    netValue: string,
    setFieldValue: (field: string, value: any) => void,
    netErrors?: string
  ) => {
    if (netValue && !netErrors) {
      const grossValue =
        Math.round((Number(netValue) / CALCULATE_VALUE) * MULTIPLIER) /
        MULTIPLIER;
      setFieldValue('grossRate', grossValue);
    }
  };

  return (
    <>
      <Input
        name="grossRate"
        label="Gross Rate"
        required
        styles={shortFieldStyles}
        disabled={isPaid}
        onBlur={() =>
          onChangeNetValue(grossRateValue, setFieldValue, grossRateError)
        }
      />
      <Input
        name="netRate"
        label="Net Rate"
        required
        onBlur={() =>
          onChangeGrossValue(netRateValue, setFieldValue, netRateError)
        }
        disabled={isPaid ? isPaid : isNetRateDisabled}
        styles={shortFieldStyles}
      />
    </>
  );
};

export default RateFields;
