import { useState, useContext } from 'react';

import { PodcastSpotAPI } from 'api/podcastSpotAPI';

import ListOfSpots from 'containers/spots/ListOfSpots';
import SpotActions from 'containers/spots/ListOfSpots/SpotActions';

import Modal from 'components/base/BaseModal';
import PageContainer from 'components/base/PageContainer/PageContainer';
import PageTitle from 'components/base/PageTitle/PageTitle';
import CommentForm from 'components/forms/spots/CommentForm';
import PodcastAircheckForm from 'components/forms/spots/PodcastAircheckForm';
import PodcastForm from 'components/forms/spots/PodcastForm';

import { BulkSelectState } from 'context/BulkSelectContext';
import { FilterContextState } from 'context/FilterContext';
import { usePopup } from 'context/GlobalPopupContext';
import { ModalContext } from 'context/ModalContext';
import { SpotActionsContext } from 'context/SpotActionsContext';
import { ToastContext } from 'context/ToastContext';
import { RefreshContextProvider } from 'context/RefreshContext';

import { prepareFilterData } from 'helpers/spotsHelper';

import { ToastType } from 'constants/enums/PopupType';
import { SpotType } from 'constants/enums/SpotTypes';
import { spotMessages } from 'constants/messages/spots';
import RESPONSE_CODES from 'constants/responseCodes';
import { spotStatuses } from 'constants/spots';

import { IFilterParams } from 'interfaces/IFilterParams';
import { ILegacySpotsTable } from 'interfaces/spots/Legacy/ILegacySpotsTable';
import { IPodcastSpotsTable } from 'interfaces/spots/Podcast/IPodcastSpotsTable';

const PODCAST_AIRCHECK_FORM_MODAL_NAME = 'podcastAircheckFormModal';
const PODCAST_FORM_MODAL_NAME = 'podcastFormModal';
const ADD_PODCAST_COMMENT_FORM_MODAL_NAME = 'addPodcastComment';

const confirmationMessagesMap = {
  [spotStatuses.canceled]: spotMessages['M-173'],
  [spotStatuses.dnr]: spotMessages['M-148'],
  [spotStatuses.error]: spotMessages['M-149'],
};

const ListOfPodcastSpots = () => {
  const { onModalOpen, onModalClose } = useContext(ModalContext);
  const { openPopup } = usePopup();
  const { onToastOpen } = useContext(ToastContext);
  const [currentSpot, setCurrentSpot] = useState<IPodcastSpotsTable | undefined>(undefined);
  const [dataProvider, setDataProvider] = useState(() => (props: IFilterParams) => PodcastSpotAPI.getList(props));

  const reloadList = () => {
    setDataProvider(() => (props: any) => PodcastSpotAPI.getList(props));
  };

  const handleResponse = (response: any) => {
    if (response?.status === RESPONSE_CODES.UNPROCESSABLE_ENTITY) {
      openPopup({
        title: response.data.error.title,
        type: response.data.error.type.toLowerCase(),
        content: response.data.error.details,
      });
    } else {
      reloadList();
    }
  };

  const changeSpotStatus = (spotId: number, newStatus: string) => {
    PodcastSpotAPI.changeStatus(spotId, newStatus).then(handleResponse);
  };

  const onDeleteClick = async (spotId: number, force?: boolean) => {
    const response = await PodcastSpotAPI.delete(spotId, force || false);
    if (response?.status === RESPONSE_CODES.UNPROCESSABLE_ENTITY) {
      openPopup({
        title: response.data.error.title,
        type: response.data.error.type.toLowerCase(),
        content: response.data.error.details,
        submitCallback: () => onDeleteClick(spotId, true),
        submitText: 'Yes, delete',
      });
      onModalOpen(response.data.error.title);
    } else {
      reloadList();
      onToastOpen(response.data.data, response.data.data, ToastType.success);
    }
  };

  const onAircheckClick = (spot?: IPodcastSpotsTable | ILegacySpotsTable) => {
    setCurrentSpot(spot as IPodcastSpotsTable);
    onModalOpen(PODCAST_AIRCHECK_FORM_MODAL_NAME);
  };

  const onAddNewClick = () => {
    setCurrentSpot(undefined);
    onModalOpen(PODCAST_FORM_MODAL_NAME);
  };

  const onEditClick = (spot: IPodcastSpotsTable | ILegacySpotsTable) => {
    setCurrentSpot(spot as IPodcastSpotsTable);
    onModalOpen(PODCAST_FORM_MODAL_NAME);
  };

  const onCommentClick = (spot: any) => {
    setCurrentSpot(spot);
    onModalOpen(ADD_PODCAST_COMMENT_FORM_MODAL_NAME);
  };

  const changeWithConfirm = (spot: IPodcastSpotsTable, newStatus: string) => {
    if (spot.order_id) {
      openPopup({
        ...confirmationMessagesMap[newStatus],
        submitCallback: () => changeSpotStatus(spot.id, newStatus),
      });
    } else {
      const today = new Date();
      const spotStartDate = new Date(spot.start_date);
      if (newStatus === spotStatuses.canceled && today > spotStartDate) {
        openPopup({
          ...spotMessages['M-32'],
          submitCallback: () => changeSpotStatus(spot.id, newStatus),
        });
      } else {
        changeSpotStatus(spot.id, newStatus);
      }
    }
  };

  const onChangeStatusClick = (spot: ILegacySpotsTable | IPodcastSpotsTable, status: string) => {
    switch (status) {
      case spotStatuses.canceled:
      case spotStatuses.error:
      case spotStatuses.dnr:
        return changeWithConfirm(spot as IPodcastSpotsTable, status);
      case spotStatuses.noStatus:
        return changeSpotStatus(spot?.id, status);
      default:
        return null;
    }
  };

  return (
    <RefreshContextProvider callback={reloadList}>
      <FilterContextState dataSerializer={(filters: object) => prepareFilterData(filters, SpotType.podcast)}>
        <SpotActionsContext.Provider
          value={{
            onAircheckClick,
            onCommentClick,
            onChangeStatusClick,
            reloadList,
            onDeleteClick,
          }}
        >
          <BulkSelectState actions={<SpotActions type={SpotType.podcast} />}>
            <PageContainer>
              <PageTitle>Podcast Spots</PageTitle>

              <ListOfSpots
                onAddNewClick={onAddNewClick}
                dataProvider={dataProvider}
                type={SpotType.podcast}
                editAction={onEditClick}
              />
              <Modal
                name={PODCAST_FORM_MODAL_NAME}
                title={currentSpot ? 'Edit Podcast Spot' : 'Add Podcast Spot'}
                onModalClose={onModalClose}
              >
                <PodcastForm
                  onModalClose={() => onModalClose(PODCAST_FORM_MODAL_NAME)}
                  successAction={reloadList}
                  editPodcastId={currentSpot?.id || 0}
                  isPaid={!!currentSpot?.payment_id || false}
                />
              </Modal>
              <Modal
                name={PODCAST_AIRCHECK_FORM_MODAL_NAME}
                title={currentSpot?.aircheck_id ? 'Edit Aircheck' : 'Add Aircheck'}
                onModalClose={onModalClose}
              >
                <PodcastAircheckForm
                  onModalClose={() => onModalClose(PODCAST_AIRCHECK_FORM_MODAL_NAME)}
                  successAction={reloadList}
                  currentSpot={currentSpot!}
                />
              </Modal>
              <Modal name={ADD_PODCAST_COMMENT_FORM_MODAL_NAME} title="Details" onModalClose={onModalClose}>
                <CommentForm
                  onModalClose={() => onModalClose(ADD_PODCAST_COMMENT_FORM_MODAL_NAME)}
                  successAction={reloadList}
                  currentSpot={currentSpot}
                  updateComment={PodcastSpotAPI.updateComment}
                />
              </Modal>
            </PageContainer>
          </BulkSelectState>
        </SpotActionsContext.Provider>
      </FilterContextState>
    </RefreshContextProvider>
  );
};

export default ListOfPodcastSpots;
