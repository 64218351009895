/* eslint-disable no-magic-numbers */
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Table, TableBody, TableContainer, Paper, IconButton, TableRow } from '@mui/material';

import { CustomIcon } from 'components/UIComponents';
import NoResultsBlock from 'components/base/tableComponents/NoResultsBlock';

import { ITableColumn } from 'interfaces/ITableColumn';

import { colors } from 'styles/globalStyles';

import ActionsMenu from '../tableComponents/ActionsMenu';
import BodyTableCellWithData from './BodyTableCellWithData';
import TableSkeleton from './TableSkeleton';
import {
  StyledTableHead as TableHead,
  StyledHeadTableCell as HeadTableCell,
  StyledBodyTableCell as BodyTableCell,
  StyledTableRow as BodyTableRow,
  Actions,
} from './styledComponents';

interface Props {
  name: string;
  columns: ITableColumn[];
  data: any[];
  editAction?: (id: number, item?: Object) => void;
  rowsAsLinks?: boolean;
  tableContainerStyles?: object;
  tableHeaderStyles?: object;
  tableRef?: React.RefObject<any>;
  showSkeleton?: boolean;
  search?: string;
  withActions?: boolean;
  actionsWidth?: number;
  tableStyle?: React.CSSProperties;
  highlightedRows?: number[];
}

const TableWithLazyLoad = ({
  name,
  columns,
  data,
  editAction,
  rowsAsLinks = false,
  tableContainerStyles = {},
  tableRef,
  showSkeleton,
  search,
  withActions = true,
  actionsWidth = 68,
  tableStyle = {},
  highlightedRows = [],
}: Props) => {
  const navigate = useNavigate();

  const handleRowClick = (name: string, id: number) => {
    return navigate(`/${name}/${id}`);
  };

  return (
    <TableContainer
      sx={{ borderRadius: 0, boxShadow: 'none', overflowY: 'auto', ...tableContainerStyles }}
      component={Paper}
      ref={tableRef}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table" style={tableStyle}>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <HeadTableCell key={index} align="right" style={column.headStyle}>
                {column.text}
              </HeadTableCell>
            ))}
            {withActions && <HeadTableCell sx={{ width: actionsWidth }} align="right" />}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <BodyTableRow
              key={index}
              onClick={rowsAsLinks ? () => handleRowClick(name, row.id) : undefined}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                backgroundColor: highlightedRows.includes(row.id) ? colors.tableHoverColor : 'unset',
              }}
            >
              {columns.map((column, index) => (
                <BodyTableCellWithData
                  search={search}
                  content={row[column.dataField]}
                  key={index}
                  style={column.style}
                  customSearchMatchRule={column.customSearchMatchRule}
                />
              ))}
              {withActions && (
                <BodyTableCell align="right" size="small" onClick={(e) => e.stopPropagation()}>
                  <Actions>
                    {editAction && (
                      <IconButton onClick={() => editAction(row.id, row)}>
                        <CustomIcon color={colors.red} name="edit" />
                      </IconButton>
                    )}
                    <ActionsMenu data={row} actions={row.actions} />
                  </Actions>
                </BodyTableCell>
              )}
            </BodyTableRow>
          ))}
          {showSkeleton ? <TableSkeleton columns={columns} withActions={withActions} /> : ''}
        </TableBody>
      </Table>
      {data.length === 0 && !showSkeleton ? <NoResultsBlock filtered={!!search} /> : ''}
    </TableContainer>
  );
};

export default TableWithLazyLoad;
