import { FormEvent, useContext } from 'react';

import Grid from '@mui/material/Unstable_Grid2';

import { Form, Formik } from 'formik';

import {
  DatePickerGroup,
  LinkButton,
  SearchableMultiSelect,
  Select,
  SubmitButton,
  ButtonsBlock,
  Checkbox,
} from 'components/UIComponents';

import { BulkSelectContext } from 'context/BulkSelectContext';
import { FilterContext } from 'context/FilterContext';

import { getProductOptions } from 'helpers/collections';
import { nonEmptyValues } from 'helpers/utils';

import { statusOptions } from 'constants/paymentOptions';

import { IFilterOptions } from 'interfaces/spots/IFilterOptions';

import { FooterGrid, controllWrapperStyles } from './styledComponents';

const emptyValues = {
  createdAtFrom: '',
  createdAtTo: '',
  status: '',
  productId: [],
  notChecked: false,
};

const PaymentFilterForm = () => {
  const { filterOptions, onFilter } = useContext(FilterContext);
  const { onChangeSelectAll } = useContext(BulkSelectContext);

  const initital = filterOptions as IFilterOptions;

  const initialValues = {
    createdAtFrom: initital.createdAtFrom || '',
    createdAtTo: initital.createdAtTo || '',
    status: initital.status || '',
    productId: initital.productId || [],
    notChecked: initital.notChecked || false,
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values) => {
        onChangeSelectAll(false);
        const present = nonEmptyValues(values);
        onFilter(present);
      }}
    >
      {({ values, handleSubmit, resetForm }) => {
        return (
          <Form>
            <Grid container columns={17} rowSpacing={2} columnSpacing={{ xs: 2, lg: 3 }}>
              <Grid xs={4}>
                <DatePickerGroup
                  startProps={{
                    name: 'createdAtFrom',
                    direction: 'column',
                    maxDate: values.createdAtTo,
                    placeholder: 'Any Date',
                  }}
                  endProps={{
                    name: 'createdAtTo',
                    direction: 'column',
                    minDate: values.createdAtFrom,
                    placeholder: 'Any Date',
                  }}
                  label="Payment Date"
                  connector="to"
                  styles={controllWrapperStyles}
                  direction="column"
                />
              </Grid>
              <Grid xs={3}>
                <Select
                  name="status"
                  label="Status"
                  styles={controllWrapperStyles}
                  direction="column"
                  options={statusOptions}
                  placeholder="Any Status"
                  emptyOptionLabel="Any Status"
                />
              </Grid>
              <Grid xs={3}>
                <SearchableMultiSelect
                  name="productId"
                  label="Product"
                  initial={initialValues.productId}
                  getOptionsList={getProductOptions}
                  styles={controllWrapperStyles}
                  direction="column"
                />
              </Grid>
              <Grid xs={2}>
                <Checkbox
                  styles={{
                    wrapper: {
                      margin: '0 !important',
                      marginTop: '18px !important',
                    },
                  }}
                  view="checkboxFirst"
                  name="notChecked"
                  label="Show Blank Payments"
                />
              </Grid>
              <FooterGrid xs={5}>
                <ButtonsBlock>
                  <LinkButton
                    onClick={() => {
                      resetForm({ values: emptyValues });
                      onChangeSelectAll(false);
                      onFilter({});
                    }}
                  >
                    Clear filter
                  </LinkButton>
                  <SubmitButton onClick={(values: FormEvent<HTMLFormElement>) => handleSubmit(values)}>
                    Apply Filter
                  </SubmitButton>
                </ButtonsBlock>
              </FooterGrid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PaymentFilterForm;
