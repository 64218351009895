import { InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';

import { colors, fontFamily } from 'styles/globalStyles';

export const StyledInput = styled(InputBase)(() => ({
  '&': {
    width: '100%',
    maxHeight: '30px',
  },
  '& .MuiInputBase-input': {
    borderRadius: '4px',
    position: 'relative',
    border: '1px solid',
    borderColor: colors.borderGrey,
    padding: '6px 8px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
    height: '100%',
    fontFamily: fontFamily,
    '&:focus': {
      borderColor: colors.focusBlue,
    },
  },
}));

export const InputFieldContainer = styled('div')({
  width: '100%',
});

const PAGE_HEADER_PART_HEIGHT = 510;
export const tableContainerStyles = {
  maxHeight: window.innerHeight - PAGE_HEADER_PART_HEIGHT,
};

export const cellStyles = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};

export const selectStatusStyles = {
  wrapper: {
    width: 550,
  },
};