import { ILegacyAircheckForm } from 'interfaces/Aircheck/ILegacyAircheckForm';
import { IBulkActionData } from 'interfaces/IBulkActionData';

import { request } from '../instance';
import { keysToSnakeCase } from '../../helpers/utils';

export const LegacyAircheckAPI = {
  createAircheck: async (
    { status, airedSpots, comment }: ILegacyAircheckForm,
    { selectAll, excludedIds, ids }: IBulkActionData,
    filterParams: object,
    force: boolean = false
  ) => {
    try {
      const response = await request({
        url: 'v1/legacy_airchecks/',
        method: 'POST',
        data: formData(
          {
            status,
            airedSpots,
            comment,
          },
          {
            selectAll,
            excludedIds,
            ids,
          },
          force
        ),
        params: filterParams,
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  updateAircheck: async (legacyAircheckId: number, { status, airedSpots, comment }: ILegacyAircheckForm) => {
    try {
      const response = await request({
        url: `v1/legacy_airchecks/${legacyAircheckId}`,
        method: 'PUT',
        data: {
          data: {
            legacy_aircheck: {
              status,
              aired_spots: airedSpots || 0,
              comment,
            },
          },
        },
      });

      return response;
    } catch (error: any) {
      return error?.response;
    }
  },

  getOne: async (legacyAircheckId: number) => {
    try {
      const response = await request({
        url: `v1/legacy_airchecks/${legacyAircheckId}`,
        method: 'GET',
      });

      return response.data;
    } catch (error: any) {
      return error?.response;
    }
  },

  getSpots: async (bulkData: IBulkActionData, filterParams = {}) => {
    try {
      const response = await request({
        url: 'v1/legacy_airchecks/spots',
        method: 'GET',
        params: {
          ...filterParams,
          ...keysToSnakeCase(bulkData),
        },
      });

      return response.data;
    } catch (error: any) {
      return error?.response;
    }
  },
};

const formData = (
  { status, airedSpots, comment }: ILegacyAircheckForm,
  { selectAll, excludedIds, ids }: IBulkActionData,
  force: boolean
) => ({
  data: {
    legacy_aircheck: {
      status,
      aired_spots: airedSpots || 0,
      comment,
    },
    select_all: selectAll,
    legacy_spots_ids: ids,
    excluded_legacy_spots_ids: excludedIds,
    force,
  },
});

export default LegacyAircheckAPI;
