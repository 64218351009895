import { CircularProgress } from '@mui/material';

import { ResultsText } from './styledComponents';

const Results = ({ value }: { value?: number }) => {
  return (
    <ResultsText>
      <span className="results-value">
        {typeof value === 'number' ? value.toLocaleString('en-US') : <CircularProgress color="inherit" size={14} />}
      </span>{' '}
      Results
    </ResultsText>
  );
};

export default Results;
