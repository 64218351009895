import { useState, useEffect, useRef } from 'react';
import { OutlinedButton, SubmitButton } from 'components/UIComponents';
import { Form, Formik } from 'formik';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';
import { FormEvent } from 'react';
import { Editor as TinyMCEEditor } from '@tinymce/tinymce-react';
import RESPONSE_CODES from 'constants/responseCodes';
import { talkingPointsValidationSchema } from 'helpers/validation/talkingPointsValidationSchema';
import { CreativeAPI } from 'api/creativeAPI';
import { ICreativeDetails } from 'interfaces/Creative/ICreativeDetails';
const { REACT_APP_TINYMCE_EDITOR_API_KEY } = process.env;

interface CreativeFormProps {
  onModalClose: () => void;
  successAction: () => void;
  currentCreative: ICreativeDetails;
}

const EMPTY_TALKING_POINTS = '<p style="text-align: initial"></p>';

const TalkingPointsForm = ({
  onModalClose,
  successAction,
  currentCreative,
}: CreativeFormProps) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const editorRef = useRef<any>(null);

  const initialValues = {
    talkingPoints: currentCreative.talking_points || EMPTY_TALKING_POINTS,
  };

  const handleEditorInit = () => {
    const tinymceElement = document.querySelector('#tinymce-block');
    if (tinymceElement instanceof HTMLElement) {
      tinymceElement.style.display = 'block';
    }
  };

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const brandingElement = document.querySelector('.tox-statusbar__branding');
      if (brandingElement instanceof HTMLElement) {
        brandingElement.style.display = 'none';
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={talkingPointsValidationSchema}
        validateOnBlur
        onSubmit={async (values) => {
          const response = await CreativeAPI.updateTalkingPoints(
            currentCreative.id,
            values
          );
          if (response?.status === RESPONSE_CODES.OK) {
            onModalClose();
            successAction();
          }
        }}
      >
        {({ errors, handleSubmit, dirty, setFieldValue }) => {
          return (
            <Form>
              <DialogContent>
                <div id="tinymce-block" style={{ display: 'none' }}>
                  <TinyMCEEditor
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue={initialValues.talkingPoints}
                    apiKey={REACT_APP_TINYMCE_EDITOR_API_KEY}
                    init={{
                      height: 450,
                      paste_webkit_styles: 'all',
                      paste_remove_styles_if_webkit: false,
                      menubar: false,
                      plugins: [
                        'advlist','autolink',
                        'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                        'fullscreen','insertdatetime','media','table','wordcount',
                      ],
                      toolbar:
                        'undo redo | blocks | bold italic underline backcolor |' +
                        ' align | lineheight | numlist bullist indent outdent | ' +
                        'code forecolor | removeformat | fontfamily fontsize',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                      setup: (editor) => {
                        editor.on('init', handleEditorInit);
                      },
                    }}
                    onEditorChange={(content) => {
                      setFieldValue('talkingPoints', content);
                    }}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <OutlinedButton onClick={onModalClose}>Cancel</OutlinedButton>
                <SubmitButton
                  disabled={
                    !dirty || !!Object.values(errors).length || isDisabled
                  }
                  onClick={(values: FormEvent<HTMLFormElement>) => {
                    setIsDisabled(true);
                    handleSubmit(values);
                  }}
                >
                  Save
                </SubmitButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default TalkingPointsForm;
