import { useState, useContext, useEffect } from 'react';
import { FormEvent } from 'react';

import { LegacyAircheckAPI } from 'api/LegacyAircheckAPI';
import { Form, Formik } from 'formik';

import { OutlinedButton, Input, SubmitButton, Select, TextArea, OverflowTip } from 'components/UIComponents';
import { FieldGroupTitleContainer } from 'components/UIComponents/layout/styledFormComponents';
import { StyledDialogActions as DialogActions } from 'components/base/BaseModal/styledDialogActions';
import { StyledDialogContent as DialogContent } from 'components/base/BaseModal/styledDialogContent';
import Popup from 'components/base/Popup';

import { BulkSelectContext } from 'context/BulkSelectContext';
import { FilterContext } from 'context/FilterContext';
import { usePopup } from 'context/GlobalPopupContext';
import { ModalContext } from 'context/ModalContext';

import { apiErrorToPopupData } from 'helpers/utils';
import { legacyAircheckValidationSchema } from 'helpers/validation/legacyAircheckValidationSchema';

import { PopupType } from 'constants/enums/PopupType';
import { ORDER_CHANGES_WARNING_COMMON, spotMessages } from 'constants/messages/spots';
import RESPONSE_CODES from 'constants/responseCodes';
import { spotStatuses } from 'constants/spots';

import { ILegacyAircheckForm } from 'interfaces/Aircheck/ILegacyAircheckForm';
import { IResponse } from 'interfaces/api';
import { ILegacySpotsTable } from 'interfaces/spots/Legacy/ILegacySpotsTable';
import TableWithLazyLoad from 'components/base/TableWithLazyLoad/TableWithLazyLoad';
import { ILegacyInfoForOrder } from 'interfaces/spots/Legacy/ILegacyInfoForOrder';
import { ITableColumn } from 'interfaces/ITableColumn';
import { datesRange } from '../../../../helpers/spotsHelper';
import { InputContainer } from 'components/base/InlineEdit/styledComponents';
import { InputFieldContainer, StyledInput, tableContainerStyles,
  cellStyles, selectStatusStyles } from './styledComponents';

interface LegacyAircheckFormProps {
  successAction: () => void;
  currentSpot: ILegacySpotsTable | undefined;
}

const statusOptions = [
  { key: spotStatuses.success, value: spotStatuses.success },
  { key: spotStatuses.dnr, value: spotStatuses.dnr },
  { key: spotStatuses.error, value: spotStatuses.error },
];

const commonColumns = () => [
  {
    text: 'Outlet',
    dataField: 'outlet',
    style: cellStyles as React.CSSProperties,
    headStyle: { width: '22%' },
  },
  {
    text: 'Product',
    dataField: 'product',
    style: cellStyles as React.CSSProperties,
    headStyle: { width: '20%' },
  },
  {
    text: 'Air Dates',
    dataField: 'dates',
    style: cellStyles as React.CSSProperties,
    headStyle: { width: '45%' },
  },
  {
    text: 'Total Gross',
    dataField: 'totalGross',
    style: cellStyles as React.CSSProperties,
    headStyle: { width: '18%' },
  },
  {
    text: 'Ordered Spots',
    dataField: 'orderedSpots',
    style: cellStyles as React.CSSProperties,
    headStyle: { width: '22%' },
  },
  {
    text: 'Aired Spots',
    dataField: 'airedSpots',
    headStyle: { width: '20%' },
  },
];

const CONFIRM_BULK_ACTION_MODAL = 'confirmBulkActionModal';
const LEGACY_AIRCHECK_FORM_MODAL_NAME = 'legacyAircheckFormModal';

const LegacyAircheckForm = ({ successAction, currentSpot }: LegacyAircheckFormProps) => {
  const { selectAll, excludedIds, ids } = useContext(BulkSelectContext);
  const { onModalOpen, onModalClose } = useContext(ModalContext);
  const { getSerializedData } = useContext(FilterContext);
  const { openPopup } = usePopup();
  const [spots, setSpots] = useState<ILegacyInfoForOrder[]>([]);
  const [columns] = useState<ITableColumn[]>(commonColumns);
  const [airedSpotsState, setAiredSpotsState] = useState<{ [key: number | string]: string }>({});
  const [validationErrors, setValidationErrors] = useState<Record<number, string | null>>({});

  const [legacyAircheckFormInformation, setLegacyAircheckFormInformation] = useState<ILegacyAircheckForm>({
    status: currentSpot ? spotStatuses.success : '',
    airedSpots: '',
    comment: '',
  });
  const legacyAircheckId = currentSpot?.aircheck_id;

  useEffect(() => {
    if (legacyAircheckId) {
      LegacyAircheckAPI.getOne(legacyAircheckId).then(({ data }) => {
        setLegacyAircheckFormInformation({
          status: currentSpot.status,
          airedSpots: currentSpot.status === spotStatuses.success ? '' : currentSpot.aired_spots,
          comment: data.comment,
        });
      });
    }
    else if(!currentSpot) {
      LegacyAircheckAPI.getSpots(
        {
          selectAll: selectAll,
          ids: ids,
          excludedIds: selectAll ? excludedIds : [],
        },
        selectAll ? getSerializedData() : {},
      )?.then(
        ({ data }: { data: { spots: ILegacyInfoForOrder[] } }) => {
          setSpots(data.spots);
          setAiredSpotsState(
            data.spots.reduce((acc, item) => ({ ...acc, [item.id]: item.aired_spots || '' }), {})
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStatusChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (name: string, value: any) => void,
    setFieldTouched: (name: string, value: any) => void
  ) => {
    const airedSpots =
      [spotStatuses.dnr, spotStatuses.error].includes(e.target.value) && currentSpot ? currentSpot.spots_number : '';
    setFieldValue('airedSpots', airedSpots);
    setFieldTouched('airedSpots', true);

    if (!currentSpot && e.target.value === spotStatuses.success) {
      const updatedState = spots.reduce((acc, spot) => {
        return {
          ...acc,
          [spot.id]: spot.spots_number.toString(),
        };
      }, {});
      setAiredSpotsState(updatedState);
    }
  };

  const getResponseHandler = (setFieldError: (name: string, value: any) => void) => {
    return (response: IResponse) => {
      if (
        response?.status === RESPONSE_CODES.UNPROCESSABLE_ENTITY &&
        response.data.error.title === 'Invalid number of aired spots.'
      ) {
        setFieldError('airedSpots', response.data.error.details);
      } else {
        onModalClose(LEGACY_AIRCHECK_FORM_MODAL_NAME);
        successAction();
      }
    };
  };

  const singleAircheck = (values: ILegacyAircheckForm, responseHandler: (response: IResponse) => void) => {
    const prepareValues = {
      ...values,
      airedSpots:
        typeof values.airedSpots === 'string'
          ? parseInt(values.airedSpots, 10)
          : values.airedSpots,
    };
    const request = () => {
      if (legacyAircheckId) {
        LegacyAircheckAPI.updateAircheck(legacyAircheckId, prepareValues).then(responseHandler);
      } else {
        LegacyAircheckAPI.createAircheck(
          prepareValues,
          {
            selectAll: false,
            ids: [currentSpot!.id],
            excludedIds: [],
          },
          {},
          true
        ).then(responseHandler);
      }
    };

    if (currentSpot!.order_id) {
      openPopup({
        ...spotMessages['M-152'],
        submitCallback: request,
      });
    } else {
      request();
    }
  };

  const bulkAircheck = (values: ILegacyAircheckForm, responseHandler: (response: IResponse) => void,
                        force: boolean) => {
    const request = (force: boolean) => {
      return LegacyAircheckAPI.createAircheck(
        values,
        {
          selectAll: selectAll,
          ids: ids,
          excludedIds: selectAll ? excludedIds : [],
        },
        selectAll ? getSerializedData() : {},
        force
      );
    };
    request(force).then((response: IResponse) => {
      if (
        response?.status === RESPONSE_CODES.UNPROCESSABLE_ENTITY &&
        response.data.error.title === ORDER_CHANGES_WARNING_COMMON.title
      ) {
        openPopup({
          ...apiErrorToPopupData(response.data.error),
          ...ORDER_CHANGES_WARNING_COMMON,
          submitCallback: () => onModalOpen(CONFIRM_BULK_ACTION_MODAL),
        });
      } else {
        responseHandler(response);
      }
    });
  };

  const handleFormSubmit = (values: ILegacyAircheckForm, setFieldError: (name: string, value: any) => void,
                            force: boolean) => {
    onModalClose(CONFIRM_BULK_ACTION_MODAL);
    const responseHandler = getResponseHandler(setFieldError);
    if (currentSpot) {
      singleAircheck(values, responseHandler);
    } else {
      bulkAircheck(
        {
          status: values.status,
          airedSpots: airedSpotsState,
          comment: values.comment,
        },
        responseHandler,
        force
      );
    }
  };

  const handleInputValidation = (value: string, orderedSpots: number): string | null => {
    if (value === '') {
      return 'Required field';
    }
    if (!/^\d+$/.test(value)) {
      return 'Must be a number';
    }
    if (parseInt(value, 10) > orderedSpots) {
      return 'Must be less than or equal to the number of ordered spots';
    }
    return null;
  };

  const handleAiredSpotsUpdate = (id: number, value: string, orderedSpots: number) => {
    const error = handleInputValidation(value, orderedSpots);
    setAiredSpotsState((prevState) => ({
      ...prevState,
      [id]: value,
    }));

    if (!error) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [id]: null,
      }));
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [id]: error,
      }));
    }
  };

  const onApplyAllClick = (spot: any) => {
    const valueToApply = airedSpotsState[spot.id];

    const updatedState = Object.keys(airedSpotsState).reduce((acc, currKey) => {
      const currentSpot = spots.find((s) => s.id.toString() === currKey);

      if (currentSpot?.payment_id) {
        return {
          ...acc,
          [currKey]: airedSpotsState[currKey],
        };
      }
      const error = handleInputValidation(valueToApply, currentSpot?.spots_number || 0);

      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [currKey]: error || null,
      }));

      return {
        ...acc,
        [currKey]: valueToApply,
      };
    }, {});

    setAiredSpotsState(updatedState);
  };


  const formatItem = (status: string) => (item: ILegacyInfoForOrder) => ({
    id: item.id,
    product: (<OverflowTip title={item.product_name}>{item.product_name}</OverflowTip>),
    outlet: (<OverflowTip title={item.outlet_name}>{item.outlet_name}</OverflowTip>),
    dates: <span>{datesRange(item, false)}</span>,
    orderedSpots: item.spots_number,
    totalGross: item.total_gross,
    airedSpots: (
      <InputContainer>
        <InputFieldContainer>
          <StyledInput
            value={airedSpotsState[item.id] || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleAiredSpotsUpdate(item.id, e.target.value, item.spots_number);
            }}
            disabled={!!item?.payment_id || status === spotStatuses.success || status === ''}
          />
          {validationErrors[item.id] && (
            <span style={{ color: 'red', fontSize: '10px' }}>{validationErrors[item.id]}</span>
          )}
        </InputFieldContainer>
      </InputContainer>
    ),
    actions: [{
      name: 'Apply to All',
      action: onApplyAllClick,
      disabled: !!item?.payment_id || status === spotStatuses.success || validationErrors[item.id] }],
  });

  return (
    <>
      <Formik
        initialValues={legacyAircheckFormInformation}
        enableReinitialize={true}
        validationSchema={currentSpot ? legacyAircheckValidationSchema(currentSpot.spots_number) : null}
        validateOnBlur
        onSubmit={(values, { setFieldError }) => {
          if (currentSpot) {
            handleFormSubmit(values, setFieldError, false);
          } else {
            if (!spots.every(spot => spot.order_id === null)) {
              handleFormSubmit(values, setFieldError, false);
            }
            else {
              onModalOpen(CONFIRM_BULK_ACTION_MODAL);
            }
          }
        }}
      >
        {({ errors, handleSubmit, values, setFieldValue, setFieldTouched, setFieldError }) => {
          return (
            <>
              <Form>
                <DialogContent>
                  <FieldGroupTitleContainer>AIRCHECK INFO</FieldGroupTitleContainer>
                  <Select
                    name="status"
                    label="Status"
                    placeholder="Select"
                    styles={selectStatusStyles}
                    options={statusOptions}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleStatusChange(e, setFieldValue, setFieldTouched);
                    }}
                    required
                    disabled={!!currentSpot?.payment_id}
                  />
                  { currentSpot ? (
                    <Input
                      name="airedSpots"
                      label="Aired Spots"
                      required
                      disabled={!!currentSpot?.payment_id || values.status === spotStatuses.success}
                    />
                  ) : '' }
                  <FieldGroupTitleContainer>COMMENT</FieldGroupTitleContainer>
                  <TextArea name="comment" />
                  { !currentSpot ? (
                    <TableWithLazyLoad
                      name="spotRecords"
                      columns={columns}
                      data={spots.map(formatItem(values.status))}
                      actionsWidth={10}
                      tableContainerStyles={tableContainerStyles}
                      tableStyle={{ display: 'table', tableLayout: 'fixed' }}
                    />
                  ) : '' }
                </DialogContent>
                <DialogActions>
                  <OutlinedButton onClick={() => onModalClose(LEGACY_AIRCHECK_FORM_MODAL_NAME)}>Cancel</OutlinedButton>
                  <SubmitButton
                    disabled={ currentSpot ? !!Object.values(errors).length  :
                      (Object.values(validationErrors).length > 0 &&
                      !Object.values(validationErrors).every((value) => value === null)) ||
                      values.status === ''}
                    onClick={(values: FormEvent<HTMLFormElement>) => {
                      handleSubmit(values);
                    }}
                  >
                    Add
                  </SubmitButton>
                </DialogActions>
              </Form>
              <Popup
                name={CONFIRM_BULK_ACTION_MODAL}
                type={PopupType.warning}
                title="Confirm Bulk Action"
                onModalClose={() => onModalClose(CONFIRM_BULK_ACTION_MODAL)}
                handleSubmit={() => handleFormSubmit(values, setFieldError, true)}
                submitText="Confirm"
              >
                You're about to perform bulk action on several Spots
              </Popup>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default LegacyAircheckForm;
