import { useState, useContext } from 'react';

import { LegacySpotAPI } from 'api/legacySpotAPI';

import ListOfSpots from 'containers/spots/ListOfSpots';
import SpotActions from 'containers/spots/ListOfSpots/SpotActions';

import Modal from 'components/base/BaseModal';
import PageContainer from 'components/base/PageContainer/PageContainer';
import PageTitle from 'components/base/PageTitle/PageTitle';
import CommentForm from 'components/forms/spots/CommentForm';
import LegacyAircheckForm from 'components/forms/spots/LegacyAircheckForm';
import LegacyForm from 'components/forms/spots/LegacyForm';

import { BulkSelectState } from 'context/BulkSelectContext';
import { FilterContextState } from 'context/FilterContext';
import { usePopup } from 'context/GlobalPopupContext';
import { ModalContext } from 'context/ModalContext';
import { SpotActionsContext } from 'context/SpotActionsContext';
import { ToastContext } from 'context/ToastContext';
import { RefreshContextProvider } from 'context/RefreshContext';

import { prepareFilterData } from 'helpers/spotsHelper';

import { ToastType } from 'constants/enums/PopupType';
import { SpotType } from 'constants/enums/SpotTypes';
import { spotMessages } from 'constants/messages/spots';
import RESPONSE_CODES from 'constants/responseCodes';
import { spotStatuses } from 'constants/spots';

import { IFilterParams } from 'interfaces/IFilterParams';
import { ILegacySpotsTable } from 'interfaces/spots/Legacy/ILegacySpotsTable';
import { IPodcastSpotsTable } from 'interfaces/spots/Podcast/IPodcastSpotsTable';

const LEGACY_AIRCHECK_FORM_MODAL_NAME = 'legacyAircheckFormModal';
const ADD_LEGACY_COMMENT_FORM_MODAL_NAME = 'addLegacyComment';
const LEGACY_FORM_MODAL_NAME = 'legacyFormModal';

const ListOfLegacySpots = () => {
  const { onModalOpen, onModalClose } = useContext(ModalContext);
  const { onToastOpen } = useContext(ToastContext);
  const [dataProvider, setDataProvider] = useState(() => (props: IFilterParams) => LegacySpotAPI.getList(props));
  const [currentSpot, setCurrentSpot] = useState<ILegacySpotsTable | undefined>(undefined);
  const { openPopup } = usePopup();

  const reloadList = () => {
    setDataProvider(() => (props: any) => LegacySpotAPI.getList(props));
  };

  const handleResponse = (response: any) => {
    if (response?.status === RESPONSE_CODES.UNPROCESSABLE_ENTITY) {
      openPopup({
        type: response.data.error.type.toLowerCase(),
        content: response.data.error.details,
        title: response.data.error.title,
      });
    } else {
      reloadList();
    }
  };

  const changeSpotStatus = async (spotId: number, newStatus: string) => {
    const response = await LegacySpotAPI.changeStatus(spotId, newStatus);
    handleResponse(response);
  };

  const onDeleteClick = async (spotId: number, force?: boolean) => {
    const response = await LegacySpotAPI.delete(spotId, force || false);
    if (response?.status === RESPONSE_CODES.UNPROCESSABLE_ENTITY) {
      openPopup({
        title: response.data.error.title,
        type: response.data.error.type.toLowerCase(),
        content: response.data.error.details,
        submitCallback: () => onDeleteClick(spotId, true),
        submitText: 'Yes, delete',
      });
    } else {
      reloadList();
      onToastOpen(response.data.data, response.data.data, ToastType.success);
    }
  };

  const onAircheckClick = (spot?: ILegacySpotsTable | IPodcastSpotsTable) => {
    setCurrentSpot(spot as ILegacySpotsTable);
    onModalOpen(LEGACY_AIRCHECK_FORM_MODAL_NAME);
  };

  const onAddNewClick = () => {
    setCurrentSpot(undefined);
    onModalOpen(LEGACY_FORM_MODAL_NAME);
  };

  const onEditClick = (spot?: ILegacySpotsTable | IPodcastSpotsTable) => {
    setCurrentSpot(spot as ILegacySpotsTable);
    onModalOpen(LEGACY_FORM_MODAL_NAME);
  };

  const onCommentClick = (spot: any) => {
    setCurrentSpot(spot);
    onModalOpen(ADD_LEGACY_COMMENT_FORM_MODAL_NAME);
  };

  const cancelAction = (spot: ILegacySpotsTable) => {
    const today = new Date();
    const spotStartDate = new Date(spot.start_date);
    const spotInOrder = spot.order_id;
    if (spotInOrder || today > spotStartDate) {
      openPopup({
        ...(spotInOrder ? spotMessages['M-146'] : spotMessages['M-32']),
        submitCallback: () => changeSpotStatus(spot.id as number, spotStatuses.canceled),
      });
    } else {
      changeSpotStatus(spot.id, spotStatuses.canceled);
    }
  };

  const onChangeStatusClick = (spot: ILegacySpotsTable | IPodcastSpotsTable, status: string) => {
    switch (status) {
      case spotStatuses.canceled:
        return cancelAction(spot as ILegacySpotsTable);
      case spotStatuses.noStatus:
        return changeSpotStatus(spot?.id, spotStatuses.noStatus);
      default:
        return null;
    }
  };

  return (
    <RefreshContextProvider callback={reloadList}>
      <FilterContextState dataSerializer={(filters: object) => prepareFilterData(filters, SpotType.legacy)}>
        <SpotActionsContext.Provider
          value={{
            onAircheckClick,
            onCommentClick,
            onChangeStatusClick,
            onDeleteClick,
            reloadList,
          }}
        >
          <BulkSelectState actions={<SpotActions type={SpotType.legacy} />}>
            <PageContainer>
              <PageTitle>Legacy Spots</PageTitle>
              <ListOfSpots
                onAddNewClick={onAddNewClick}
                dataProvider={dataProvider}
                type={SpotType.legacy}
                editAction={onEditClick}
              />
              <Modal
                name={LEGACY_FORM_MODAL_NAME}
                title={currentSpot ? 'Edit Legacy Spot' : 'Add Legacy Spot'}
                onModalClose={onModalClose}
              >
                <LegacyForm
                  onModalClose={() => onModalClose(LEGACY_FORM_MODAL_NAME)}
                  successAction={reloadList}
                  editLegacyId={currentSpot?.id || 0}
                  isPaid={!!currentSpot?.payment_id || false}
                />
              </Modal>
              <Modal
                name={LEGACY_AIRCHECK_FORM_MODAL_NAME}
                title={currentSpot?.aircheck_id ? 'Edit Aircheck' : 'Add Aircheck'}
                onModalClose={onModalClose}
                size="md"
              >
                <LegacyAircheckForm successAction={reloadList} currentSpot={currentSpot} />
              </Modal>
              <Modal name={ADD_LEGACY_COMMENT_FORM_MODAL_NAME} title="Details" onModalClose={onModalClose}>
                <CommentForm
                  onModalClose={() => onModalClose(ADD_LEGACY_COMMENT_FORM_MODAL_NAME)}
                  successAction={reloadList}
                  currentSpot={currentSpot}
                  updateComment={LegacySpotAPI.updateComment}
                />
              </Modal>
            </PageContainer>
          </BulkSelectState>
        </SpotActionsContext.Provider>
      </FilterContextState>
    </RefreshContextProvider>
  );
};

export default ListOfLegacySpots;
