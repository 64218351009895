import { RouterProvider } from 'react-router-dom';

import { Global } from '@emotion/react';
import router from 'router';

import { setUpInterceptors } from './api/setUpInterceptors';
import { GlobalStyles } from './styles/globalStyles';
import { useEffect } from 'react';
const { REACT_APP_ENVIRONMENT } = process.env;

function App() {
  setUpInterceptors();
  useEffect(() => {
    const favicon = document.querySelector("link[rel~='icon']");

    if (REACT_APP_ENVIRONMENT === 'staging' && favicon) {
      favicon.setAttribute('href', '/staging-favicon.png');
    }
  }, []);

  return (
    <>
      <Global styles={GlobalStyles} />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
